@font-face {
  font-family: 'ABBVoice';
  src:
    url('../public/fonts/en/EOT/ABBvoice_W_Lt.eot') format('embedded-opentype'),
    url('../public/fonts/en/WOFF/ABBvoice_W_Lt.woff') format('woff'),
    url('../public/fonts/en/WOFF2/ABBvoice_W_Lt.woff2') format('woff2');
  font-weight: 100 300;
  font-style: normal italic;
}

@font-face {
  font-family: 'ABBVoice';
  src:
    url('../public/fonts/en/EOT/ABBvoice_W_Rg.eot') format('embedded-opentype'),
    url('../public/fonts/en/WOFF/ABBvoice_W_Rg.woff') format('woff'),
    url('../public/fonts/en/WOFF2/ABBvoice_W_Rg.woff2') format('woff2');
  font-weight: 400;
  font-style: normal italic;
}

@font-face {
  font-family: 'ABBVoice';
  src:
    url('../public/fonts/en/EOT/ABBvoice_W_Md.eot') format('embedded-opentype'),
    url('../public/fonts/en/WOFF/ABBvoice_W_Md.woff') format('woff'),
    url('../public/fonts/en/WOFF2/ABBvoice_W_Md.woff2') format('woff2');
  font-weight: 500 700;
  font-style: normal italic;
}

@font-face {
  font-family: 'ABBVoice';
  src:
    url('../public/fonts/en/EOT/ABBvoice_W_Bd.eot') format('embedded-opentype'),
    url('../public/fonts/en/WOFF/ABBvoice_W_Bd.woff') format('woff'),
    url('../public/fonts/en/WOFF2/ABBvoice_W_Bd.woff2') format('woff2');
  font-weight: 800 900;
  font-style: normal italic;
}

@font-face {
    font-family: 'ABBVoiceDisplay';
    src: url('../public/fonts/en/WOFF/ABBvoiceDisplay_W_SBd.woff') format('woff'), url('../public/fonts/en/WOFF2/ABBvoiceDisplay_W_SBd.woff2') format('woff2');
    font-weight: 500 700;
    font-style: normal italic;
}

body {
  margin: 0;
  font-family: 'ABBVoice' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'ABBVoice' !important;
}

input {
  font-family: 'ABBVoice' !important;
}

@media (max-width: 768px) {
    .br {
        display: contents;
    }
}

.abbTagline {
    width: 250px;
    height: 97px;
    background-position: center center;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-image: url('../public/images/Desktop-ABB-TAGLINE.svg')
}


@media (max-width: 768px) {
    .abbTagline {
        left: 0px;
        background-image: url('../public/images/Mobile-ABB-TAGLINE.svg')
    }
}